<template>
  <div class="ChallengeCreateView">
    <div class="header">
      <div class="title">
        {{ $t('title') }}
      </div>
      <div
        class="backButton --global-clickable"
        @click="navigateBack()">
        Go back
      </div>
    </div>
    <div class="body">
      <AvatarVsAvatar />
      <!-- <div>{{ $t('instruction') }}</div> -->
      <br><br><br>
      <div>
        <div
          v-for="(option, i) of options"
          :key="i"
          class="Option --global-clickable"
          :class="{ selected: i === selectedOptionIndex }"
          @click="selectedOptionIndex = i">
          <!-- <div class="Option__label">{{ $t(`options.${option.id}.label`) }}</div>
          <div class="Option__text">{{ $t(`options.${option.id}.text`) }}</div> -->
          <div class="Option__label">
            {{ option.i18n.label }}
          </div>
          <div class="Option__text">
            {{ option.i18n.text }}
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div
        v-if="challengeInvitationUrl"
        class="facebookAlternative">
        <div
          class="--global-clickable"
          @click="copyInvitationUrlToClipboard()">
          Invitation url to clipboard
        </div>
        <div
          class="--global-clickable"
          @click="startSession()">
          Play
        </div>
      </div>

      <div
        class="shareButton --global-clickable"
        :class="{ disabled: !challengeId }"
        @click="openShareDialog()">
        {{ $t('shareButton') }}
      </div>
    </div>
  </div>
</template>

<translations>
  title: 'New Challenge'
  instruction: 'Select Difficulty'
  options:
    easy:
      label: 'Easy'
      label_no: 'Lett'
      text: 'Remember 20 items'
    medium:
      label: 'Medium'
      label_no: 'Middels'
      text: 'Remember 20 items with prices'
    hard:
      label: 'Hardcore'
      label_no: 'Vanskelig'
      text: 'Remember 35 items and prices'
  shareButton: 'Send Challenge'
</translations>

<script>
import { copyStringToClipboard } from '@/utils';
import gameHelper from '@/modules/games-shared/game-helper';
import { installSdk, openShareDialog } from '@/facebook';
import AvatarVsAvatar from '../components/AvatarVsAvatar';

export default {
    components: { AvatarVsAvatar },
    props: {
        gameId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            selectedOptionIndex: -1,
            challengeId: null,
            groupInvitationId: null
        };
    },
    computed: {
        challengeInvitationUrl() {
            if (!this.challengeId) {
                return null;
            }
            return (
                location.origin +
                this.$router.resolve({
                    name: 'MemogeniusGame-ChallengeInvitation',
                    params: {
                        gameId: this.gameId,
                        challengeId: this.challengeId,
                        groupInvitationId: this.groupInvitationId
                    }
                }).href
            );
        }
    },
    watch: {
        async selectedOptionIndex(optionIndex) {
            const unusedGroupId = this.groupId;
            this.groupId = null;
            this.challengeId = null;
            this.groupInvitationId = null;
            if (unusedGroupId) {
                await this.$store.dispatch('moduleSocial/deleteGroup', { groupId: unusedGroupId });
            }

            const settings = {
                gameId: this.gameId,
                sessionOptions: this.options[optionIndex].sessionOptions
            };

            await installSdk();

            const { challengeId, groupInvitationId, groupId } = await this.$store.dispatch(
                'moduleSocial/createNewGroupWithChallengeAndInvitation',
                {
                    challengeType: 'memogeniusSingleSession',
                    challengeText: 'challenge text...',
                    settings: JSON.stringify(settings)
                }
            );
            this.groupId = groupId;
            this.challengeId = challengeId;
            this.groupInvitationId = groupInvitationId;
        }
    },
    created() {
        this.options = require('@/modules/game-' + this.gameId + '/challenge-presets').default;
    },

    beforeDestroy() {
        if (this.groupId) {
            this.$store.dispatch('moduleSocial/deleteGroup', { groupId: this.groupId });
        }
    },
    methods: {
        selectOption(optionId) {},
        async openShareDialog() {
            if (!this.challengeInvitationUrl) {
                return;
            }
            if (await openShareDialog(this.challengeInvitationUrl)) {
                this.startSession();
            } else {
                // console.log('do nothing')
            }
        },
        navigateBack() {
            this.$router.push({
                name: 'MemogeniusGame-Challenges'
            });
        },
        startSession() {
            gameHelper.createChallengeSession({
                challengeId: this.challengeId,
                navigate: true
            });
            this.groupId = null;
            this.challengeId = null;
            this.groupInvitationId = null;
        },
        copyInvitationUrlToClipboard() {
            copyStringToClipboard(this.challengeInvitationUrl);
        }
    }
};
</script>

<style scoped lang="scss">
$sideMargin: 2em;

.ChallengeCreateView {
    display: flex;
    flex-direction: column;
}
.header {
    position: relative;
    height: 30%;
    background-color: rgba(black, 0.1);
}
.title {
    font-weight: 600;
    text-align: center;
    padding: 1em;
}
.backButton {
    color: rgba(white, 0.5);
    position: absolute;
    padding: 1em;
    top: 0em;
    left: 0;
}
.AvatarVsAvatar {
    margin-top: -2.25em;
}
.body {
    flex: 1;
    padding: 0 $sideMargin;
}

.Option {
    background: white;
    color: black;
    padding: 1em 2em;
    text-align: center;
    border-radius: 3em;
    border: 2px solid white;

    &:not(:first-child) {
        margin-top: 1em;
    }

    &.selected {
        background-color: transparent;
        border: 2px solid rgba(black, 0.2);
    }
}
.Option__label {
    font-weight: 600;
    font-size: 110%;
}
.Option__text {
    color: rgba(black, 0.5);
    font-size: 90%;
    margin-top: 0.2em;
}

.footer {
    padding: 1em $sideMargin;
}

.shareButton {
    background-color: blue;
    color: white;
    padding: 1.5em 2em;
    border-radius: 3em;
    text-align: center;

    &.disabled {
        opacity: 0.2;
        cursor: default;
    }
}

.facebookAlternative {
    text-align: center;
    display: flex;
    justify-content: center;
    margin-bottom: 1em;

    & > * {
        padding: 0.5em 1em;
        background: white;
        color: black;
        margin: 0 0.5em;
        border-radius: 0.3em;
    }
}
</style>
