/* global FB */
import settings from '@/settings';

let installed = false;

export function installSdk() {
    if (installed) {
        return;
    }
    return new Promise(resolve => {
        window.fbAsyncInit = function () {
            FB.init({
                appId: settings.facebookClientId,
                xfbml: true,
                version: 'v3.2' // 'v2.5'
            });
            installed = true;
            resolve();
        };

        /* eslint-disable */
        (function (d, s, id) {
            var js,
                fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {
                return;
            }
            js = d.createElement(s);
            js.id = id;
            js.src = 'https://connect.facebook.net/en_US/sdk.js';
            fjs.parentNode.insertBefore(js, fjs);
        })(document, 'script', 'facebook-jssdk');
        /* eslint-enable */
    });
}

export async function openSendDialog() {
    // await installSdk()
    FB.ui({
        method: 'send',
        // redirect_uri: location.href,
        // display: 'popup',
        // mobile_iframe: true,
        // action_type: 'og.likes',
        link: 'http://www.nytimes.com/interactive/2015/04/15/travel/europe-favorite-streets.html'
    });
}

export async function openShareDialog(url) {
    return new Promise(resolve => {
        FB.ui(
            {
                method: 'share',
                // action_type: 'og.likes',
                // action_properties: JSON.stringify({
                //   object: 'https://developers.facebook.com/docs/'
                // }),
                // href: url // 'http://www.memolife.com/flytoget-share'
                href: 'http://www.memolife.com/flytoget-share'
            },
            response => {
                if (response && !response.error_message) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            }
        );
    });
}
